import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  Button,
  Stack,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import Logo from "../src/images/Logo.svg";
import { Link } from "react-router-dom";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        (event.type === "keydown" &&
          (event as React.KeyboardEvent).key === "Tab") ||
        (event as React.KeyboardEvent).key === "Shift"
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {["Home", "Problem & Solution", "About Us", "Contact"].map((text) => (
          <ListItem
            button
            key={text}
            component={Link}
            to={`/${text
              .toLowerCase()
              .replace(/ and /g, "-")
              .replace(/ /g, "-")}`}
            sx={{
              color: "#303981",
              "&:hover": {
                backgroundColor: "rgba(48, 57, 129, 0.1)",
              },
              padding: "10px 16px",
            }}
          >
            <ListItemText primary={text} />
          </ListItem>
        ))}
        <ListItem>
          <Button
            variant="contained"
            sx={{ bgcolor: "#303981", borderRadius: "100px", color: "white" }}
          >
            Login/Register
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          background: `rgba(48, 57, 128, 0.1)`,
          backgroundSize: "30px 30px",
          backgroundPosition: "20px ,20px, 20px 20px",
          paddingTop: "10px",
          boxShadow: "none",
          color: "#303981",
          fontFamily: "sans-serif",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 16px",
          }}
        >
          {isMobile && (
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ color: "#303981" }}
            >
              <ListIcon />
            </IconButton>
          )}
          <Box
            sx={{
              flexGrow: isMobile ? 1 : 0,
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                height: "35px",
              }}
            />
          </Box>
          {!isMobile && (
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              textColor="inherit"
              indicatorColor="primary"
              sx={{
                "& .MuiTab-root": {
                  color: "#303981",
                  textTransform: "none",
                  minWidth: "auto",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                },
                "& .Mui-selected": {
                  color: "#303981",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#303981",
                },
              }}
            >
              <Tab component={Link} to="/home" label="Home" value={0} />
              <Tab
                component={Link}
                to="/problem-&-solution"
                label="Problem & Solution"
                value={1}
                sx={{ ml: 3 }}
              />
              <Tab
                component={Link}
                to="/about-us"
                label="About Us"
                value={2}
                sx={{ ml: 3 }}
              />
              <Tab
                component={Link}
                to="/contact"
                label="Contact"
                value={3}
                sx={{ ml: 3 }}
              />
            </Tabs>
          )}
          {!isMobile && (
            <Button
              variant="contained"
              sx={{
                bgcolor: "#303981",
                borderRadius: "100px",
                color: "white",
                ml: 3,
              }}
            >
              Login/Register
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
    </>
  );
}

export default Header;
