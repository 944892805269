import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Image13 from "../src/images/image 13.svg";
import Image33 from "../src/images/Frame 33.svg";
import Image331 from "../src/images/Frame 33 (1).svg";
import Image12 from "../src/images/image 12.svg";
import Icon1 from "../src/images/Vector (1).svg";
import Icon2 from "../src/images/token_mind.svg";
import Icon3 from "../src/images/ph_money.svg";
import Icon332 from "../src/images/Frame 33 (2).svg";
import Icon4 from "../src/images/material-symbols_security.svg";
import Icon5 from "../src/images/ph_money (1).svg";
import Icon6 from "../src/images/Vector (2).svg";
import Image63 from "../src/images/Frame 63 (2).svg";
import Footer from "./Footer";
import FooterLogo from "../src/images/Logo (1).svg";

export default function About() {
  // const backgroundStyle = {
  //   background: `
  //         radial-gradient(circle, rgba(48, 57, 129, 0.1) 1px, transparent 3px),
  //         radial-gradient(circle, rgba(48, 57, 129, 0.1) 1px, transparent 3px)
  //       `,
  //   backgroundSize: "30px 30px",
  //   backgroundPosition: "20px ,20px, 20px 20px",
  //   minHeight: "100vh",
  //   padding: "5px",
  // };
  return (
    <>
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 15,
          }}
        >
          <Stack spacing={2} alignItems="center">
            <Typography
              variant="h3"
              fontWeight={700}
              color={"#1F2870"}
              fontFamily={"sans-serif"}
              textAlign="center"
            >
              About AssuredPay
            </Typography>
            <Typography
              variant="caption"
              color={"#5F5E61"}
              fontFamily={"sans-serif"}
              textAlign="center"
              pl={4}
            >
              Pioneering Financial Transformation for Indian MSMEs
            </Typography>
            <Stack flexDirection={"row"} gap={2}>
              <Button
                sx={{
                  borderRadius: 20,
                  color: "#1F2870",
                  bgcolor: "#E4E5FF",
                  fontFamily: "sans-serif",
                  fontWeight: 550,
                  "&:hover": {
                    bgcolor: "#E4E5FF",
                  },
                }}
                variant="contained"
                size="small"
              >
                Why Assured Pay?
              </Button>
              <Button
                sx={{
                  borderRadius: 20,
                  color: "#1F2870",
                  bgcolor: "#FFD8C2",
                  fontFamily: "sans-serif",
                  fontWeight: 550,
                  "&:hover": {
                    bgcolor: "#FFD8C2",
                  },
                }}
                variant="contained"
              >
                Business Finance
              </Button>
              <Button
                sx={{
                  borderRadius: 20,
                  color: "#1F2870",
                  bgcolor: "#E4E1E9",
                  fontFamily: "sans-serif",
                  fontWeight: 550,
                  "&:hover": {
                    bgcolor: "#E4E1E9",
                  },
                }}
                variant="contained"
              >
                Our Vision
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "70vh",
            overflow: "hidden",
            mt: 18,
          }}
        >
          <img
            src={Image13}
            alt="Background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Grid
            container
            spacing={4}
            sx={{
              position: "relative",
              zIndex: 1,
              padding: "0px 35px 0px 35px",
              mt: 8,
              justifyContent: { xs: "space-between", md: "space-between" },
            }}
          >
            <Grid item xs={12} md={4}>
              <Typography
                variant="h3"
                fontWeight={200}
                color="#1F2870"
                fontFamily="sans-serif"
                width={{ xs: "100%", md: "50%" }}
              >
                Unlocking the Future of Business Finance
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} textAlign="center">
              <img
                src={Image33}
                alt="Image33"
                style={{ width: "100%", maxWidth: 300 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                width={{ xs: "100%" }}
              >
                Welcome to AssuredPay, where we are revolutionizing financial
                management for Indian Micro, Small and Medium Enterprises
                (MSMEs). We understand the unique challenges that businesses in
                this sector face, and we've built a robust platform to address
                them head-on.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "70vh",
            overflow: "hidden",
          }}
        >
          <img
            src={Image12}
            alt="Background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Grid
            container
            spacing={4}
            sx={{
              position: "relative",
              zIndex: 1,
              padding: "0px 35px 0px 35px",
              mt: 8,
              justifyContent: { xs: "space-between", md: "space-between" },
            }}
          >
            <Grid item xs={12} md={4}>
              <Typography
                variant="h3"
                fontWeight={200}
                color="#1F2870"
                fontFamily="sans-serif"
                width={{ xs: "100%", md: "50%" }}
              >
                Our Commitment to You
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} textAlign="center">
              <img
                src={Image331}
                alt="Image33"
                style={{ width: "100%", maxWidth: 300 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                width={{ xs: "100%" }}
              >
                At AssuredPay, we aren't just another payment solution. We are
                your strategic partner on the path towards your business's
                financial success. Our unwavering commitment is to empower
                Indian MSMEs with innovative tools and services and
                significantly reduce financial stress to drive business growth.
                We embark on this road to help every MSME seamlessly adapt to
                the Digital Transformation.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            mb: 4,
            mt: { xs: 4, sm: 6, md: 8, lg: 10 },
            p: { xs: 1, sm: 2, md: 5 },
            flexWrap: { xs: "wrap", md: "nowrap" },
          }}
        >
          <Typography
            variant="h5"
            fontWeight={700}
            fontFamily={"sans-serif"}
            sx={{
              flexBasis: { xs: "100%", md: "auto" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Why Assured Pay?
          </Typography>
          <Divider
            sx={{
              flexGrow: 1,
              borderWidth: 1,
              borderColor: "#9CA5F5",
              width: { xs: "95%", md: "80%" },
              my: { xs: 2, md: 0 },
            }}
          />
        </Stack>
        <Box sx={{ mt: 6, p: 4 }}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 5,
                  border: "1px solid #9CA5F5",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    bgcolor: "#FEE7DB",
                    borderRadius: 2,
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    "&:hover": {
                      bgcolor: "#FEE7DB",
                      opacity: 1,
                    },
                  }}
                >
                  <img
                    src={Icon1}
                    alt="Icon1"
                    style={{
                      marginRight: 8,
                      width: 24,
                    }}
                  />
                  <Typography variant="button" color="#303981"></Typography>
                </Button>
                <Typography
                  variant="h5"
                  color={"#303981"}
                  fontFamily={"sans-serif"}
                  mt={3}
                >
                  Payment Assurance: We Assure On-Time Payments
                </Typography>
                <Typography
                  color="#46464E"
                  fontFamily="sans-serif"
                  mt={3}
                  width="100%"
                >
                  AssuredPay is your fortress against late or missing payments.
                  Our platform ensures that your payments for credit sales
                  arrive promptly, providing you with the financial stability
                  you deserve.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 5,
                  border: "1px solid #9CA5F5",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    bgcolor: "#F3FCDA",
                    borderRadius: 2,
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    "&:hover": {
                      bgcolor: "#F3FCDA",
                      opacity: 1,
                    },
                  }}
                >
                  <img
                    src={Icon2}
                    alt="Icon2"
                    style={{
                      marginRight: 8,
                      width: 24,
                    }}
                  />
                  <Typography variant="button" color="#303981"></Typography>
                </Button>
                <Typography
                  variant="h5"
                  color={"#303981"}
                  fontFamily={"sans-serif"}
                  mt={2}
                >
                  Fund Flow Empowerment: Harness the Power of Future Receivables
                </Typography>
                <Typography
                  color="#46464E"
                  fontFamily="sans-serif"
                  mt={1}
                  width="100%"
                >
                  We empower you to use future receivables to book payments,
                  transforming your cash flow management. This newfound
                  flexibility opens doors to business expansion and
                  opportunities at a much lower operating ratio.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 5,
                  border: "1px solid #9CA5F5",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    bgcolor: "#F5EDFB",
                    borderRadius: 2,
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    "&:hover": {
                      bgcolor: "#F5EDFB",
                      opacity: 1,
                    },
                  }}
                >
                  <img
                    src={Icon3}
                    alt="Icon3"
                    style={{
                      marginRight: 8,
                      width: 24,
                    }}
                  />
                  <Typography variant="button" color="#303981"></Typography>
                </Button>
                <Typography
                  variant="h5"
                  color={"#303981"}
                  fontFamily={"sans-serif"}
                  mt={2}
                >
                  Predictive Insights: Strategic Decision-Making Made Easy
                </Typography>
                <Typography
                  color="#46464E"
                  fontFamily="sans-serif"
                  mt={1}
                  width="100%"
                >
                  AssuredPay equips you with predictive analytical tools that
                  offer a crystal-clear view of your payments and receivables.
                  With this foresight, you can make informed financial decisions
                  that position you ahead of the competition.
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box bgcolor={"#F3F0F4"} p={{ xs: 2, sm: 4, md: 12 }} mt={15}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={5}
            alignItems="center"
            justifyContent="center"
            p={2}
          >
            <Grid>
              <img src={Icon332} alt="Icon332" />
            </Grid>
            <Grid>
              <Typography
                variant="h3"
                fontWeight={700}
                fontFamily={"sans-serif"}
              >
                Our Vision
              </Typography>
              <Stack direction="row" gap={2} mt={1} flexWrap="wrap">
                <Button
                  sx={{
                    borderRadius: 20,
                    color: "#1F2870",
                    bgcolor: "#E4E5FF",
                    fontFamily: "sans-serif",
                    fontWeight: 550,
                    "&:hover": {
                      bgcolor: "#E4E5FF",
                    },
                  }}
                  variant="contained"
                  size="small"
                >
                  Grow Exponentially
                </Button>
                <Button
                  sx={{
                    borderRadius: 20,
                    color: "#1F2870",
                    bgcolor: "#FFD8C2",
                    fontFamily: "sans-serif",
                    fontWeight: 550,
                    "&:hover": {
                      bgcolor: "#FFD8C2",
                    },
                  }}
                  variant="contained"
                >
                  Self-Sustainable
                </Button>
                <Button
                  sx={{
                    borderRadius: 20,
                    color: "#1F2870",
                    bgcolor: "#E4E1E9",
                    fontFamily: "sans-serif",
                    fontWeight: 550,
                    "&:hover": {
                      bgcolor: "#E4E1E9",
                    },
                  }}
                  variant="contained"
                >
                  Learn & Grow in
                </Button>
              </Stack>
              <Typography
                color="#46464E"
                fontFamily="sans-serif"
                mt={2}
                width={{ xs: "80%", sm: "auto" }}
              >
                We envision a future where Indian MSMEs operate with confidence,
                unburdened by financial constraints and grow exponentially with
                AssuredPay's financial tool, superior customer engagement &
                service. We aim to value every relationship with our customers,
                vendors, and people. We constantly strive for excellence,
                leading AssuredPay to become self-sustainable and profitable for
                our investors & an organization for employees to learn and grow
                in.
              </Typography>
            </Grid>
          </Stack>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            mb: 4,
            mt: { xs: 4, sm: 6, md: 8, lg: 10 },
            p: { xs: 1, sm: 2, md: 6 },
            flexWrap: { xs: "wrap", md: "nowrap" },
          }}
        >
          <Typography
            variant="h5"
            fontWeight={700}
            fontFamily={"sans-serif"}
            sx={{
              flexBasis: { xs: "100%", md: "auto" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            The AssuredPay Advantage
          </Typography>
          <Divider
            sx={{
              flexGrow: 1,
              borderWidth: 1,
              borderColor: "#9CA5F5",
              width: { xs: "95%", md: "60%" },
              my: { xs: 2, md: 0 },
            }}
          />
        </Stack>
        <Box sx={{ mt: 6, p: 5 }}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 5,
                  border: "1px solid #9CA5F5",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    bgcolor: "#FEE7DB",
                    borderRadius: 2,
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    "&:hover": {
                      bgcolor: "#FEE7DB",
                      opacity: 1,
                    },
                  }}
                >
                  <img
                    src={Icon4}
                    alt="Icon1"
                    style={{
                      marginRight: 8,
                      width: 24,
                    }}
                  />
                  <Typography variant="button" color="#303981"></Typography>
                </Button>
                <Typography
                  variant="h5"
                  color={"#303981"}
                  fontFamily={"sans-serif"}
                  mt={6}
                >
                  Financial Security:
                </Typography>
                <Typography
                  color="#46464E"
                  fontFamily="sans-serif"
                  mt={6}
                  width="100%"
                >
                  We ensure that you receive payments on time and never worry
                  about your payment delays again
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 5,
                  border: "1px solid #9CA5F5",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    bgcolor: "#F3FCDA",
                    borderRadius: 2,
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    "&:hover": {
                      bgcolor: "#F3FCDA",
                      opacity: 1,
                    },
                  }}
                >
                  <img
                    src={Icon5}
                    alt="Icon2"
                    style={{
                      marginRight: 8,
                      width: 24,
                    }}
                  />
                  <Typography variant="button" color="#303981"></Typography>
                </Button>
                <Typography
                  variant="h5"
                  color={"#303981"}
                  fontFamily={"sans-serif"}
                  mt={6}
                >
                  Cash Flow Optimization:
                </Typography>
                <Typography
                  color="#46464E"
                  fontFamily="sans-serif"
                  mt={6}
                  width="100%"
                >
                  Use your future receivables to book payments strategically, to
                  improve cash flow and business expansion opportunities.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 5,
                  border: "1px solid #9CA5F5",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    bgcolor: "#F5EDFB",
                    borderRadius: 2,
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    "&:hover": {
                      bgcolor: "#F5EDFB",
                      opacity: 1,
                    },
                  }}
                >
                  <img
                    src={Icon6}
                    alt="Icon3"
                    style={{
                      marginRight: 8,
                      width: 24,
                    }}
                  />
                  <Typography variant="button" color="#303981"></Typography>
                </Button>
                <Typography
                  variant="h5"
                  color={"#303981"}
                  fontFamily={"sans-serif"}
                  mt={2}
                >
                  Strategic Growth:
                </Typography>
                <Typography
                  color="#46464E"
                  fontFamily="sans-serif"
                  mt={1}
                  width="100%"
                >
                  Our predictive insights help you chart your financial future
                  and make decisions that give you a competitive edge. Join
                  AssuredPay today and experience the freedom to grow your
                  business without financial constraints. Your journey to
                  financial peace of mind starts here.
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "30vh",
            overflow: "hidden",
            borderRadius: 15,
            p: { xs: 0, sm: 4, md: 8 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mx: "auto",
            maxWidth: "1200px",
            mt: { xs: 8, sm: 10, md: 15 },
            mb: { xs: 8, sm: 10, md: 15 },
          }}
        >
          <img
            src={Image63}
            alt="Background"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, sm: 50 }}
            alignItems="center"
            justifyContent="center"
            mb={{ xs: 2, sm: 3 }}
            p={{ xs: 2, sm: 4 }}
          >
            <Typography
              variant="h3"
              fontWeight={700}
              color="white"
              fontFamily="sans-serif"
              textAlign="center"
            >
              Join Us
            </Typography>
            <img
              src={FooterLogo}
              alt="FooterLogo"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Stack>

          <Card
            sx={{
              bgcolor: "#424B8D",
              width: "90%",
              maxWidth: "800px",
              p: { xs: 2, sm: 3, md: 4 },
              mb: { xs: 2, sm: 4, md: 6 },
              textAlign: "center",
            }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="white"
                fontFamily="sans-serif"
                width={{ xs: "100%", sm: "70%" }}
                p={2}
              >
                Many MSMEs lack real-time visibility into their fund flows. This
                financial blind spot makes it challenging to plan and allocate
                resources effectively, leading to uncertainty and risk.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#FA8334",
                  borderRadius: 10,
                  padding: { xs: 1, sm: 2 },
                  display: "flex",
                  alignItems: "center",
                  width: { xs: "100%", sm: 200 },
                  "&:hover": {
                    bgcolor: "#FA8334",
                    opacity: 1,
                  },
                }}
              >
                Get Started
              </Button>
            </Stack>
          </Card>
        </Box>

        <Footer />
      </div>
    </>
  );
}
